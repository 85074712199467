<template>
  <ca-card class="mb-3">
    <template #header>
      <div class="row">
        <div class="col">
          <h4 class="mb-0">
            Daten
          </h4>
        </div>
      </div>
    </template>
    <template #body>
      <basic-switch
        v-model="blacklistedChassis.active"
        :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
        :horizontal="true"
        label="Eintrag"
      >
        <template #label>
          Eintrag Aktiv / Inaktiv
        </template>
      </basic-switch>
      <basic-input
        v-model="blacklistedChassis.chassi"
        :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
        :horizontal="true"
        :validation="{
          min: 17,
          max: 17
        }"
        maxlength="17"
        label="Fahrgestellnummer (FIN)"
        placeholder="XXXXXXXXXXXXXXXXX"
        required
        input-prevent-default
        @input="sanitizeChassi"
      >
        <template #small>
          <small>Bitte die 17 stellige Fahrgestellnummer eingeben.</small>
          <small
            v-if="chassiInputManipulated"
            class="text-danger"
          ><br>Die FIN darf nur aus arabischen  Ziffern und lateinischen Buchstaben, außer I, O und Q, bestehen</small>
        </template>
      </basic-input>

      <basic-select
        v-model="blacklistedChassis.reasonId"
        :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
        :horizontal="true"
        label="Grund"
        placeholder="Bitte auswählen..."
        :options="reasons"
        required
      />
      <basic-textarea
        v-if="reasonAllowsCustomText"
        v-model="blacklistedChassis.reasonText"
        :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
        :horizontal="true"
        label="Zusätzliche Begründung"
        placeholder="Text..."
        required
      />
    </template>
  </ca-card>
</template>

<script>
import BasicInput from '@/components/BaseComponents/BasicInput'
import BasicSelect from '@/components/BaseComponents/BasicSelect'
import BasicTextarea from '@/components/BaseComponents/BasicTextarea'
import BasicSwitch from '@/components/BaseComponents/BasicSwitch'
import CaCard from '@/components/Card'
import SanitizeChassi from '../../../helpers/sanitizeChassi'
export default {
  components: {
    BasicInput,
    BasicSelect,
    BasicTextarea,
    BasicSwitch,
    CaCard
  },
  props: {
    blacklistedChassis: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    chassiInputManipulated: false,
    reasonsLoading: true,
    reasons: [],
    reasonIdsWithCustomText: []
  }),
  computed: {
    reasonAllowsCustomText: function () {
      return this.reasonIdsWithCustomText.includes(this.blacklistedChassis.reasonId)
    }
  },
  created () {
    this.findReasons()
  },
  methods: {
    sanitizeChassi (chassi) {
      const sanitized = SanitizeChassi(chassi)
      this.chassiInputManipulated = sanitized.manipulated
      this.blacklistedChassis.chassi = sanitized.chassi
    },
    async findReasons () {
      this.reasonsLoading = true
      const reasons = (await this.$store.dispatch('blacklisted-chassis-reasons/find')).data

      this.reasons = reasons.map(reason => {
        return { label: reason.label, value: reason._id }
      })

      this.reasonIdsWithCustomText = reasons.filter(reason => reason.allowCustomText).map(reason => {
        return reason._id
      })

      this.reasonsLoading = false
    }
  }
}
</script>

<style>

</style>
